import React from "react"
import { Button } from "./Button"
import { SAL_PROPS, URIS } from "../constants"

export const CallToAction = ({ className }) => (
  <div {...SAL_PROPS} className={`flex flex-col text-center ${className}`}>
    <h2 className="px-4 mb-6">Ready to Sequence your product?</h2>

    <Button href={URIS.docs} className="mx-auto">
      Start Building
    </Button>
  </div>
)
