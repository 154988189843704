import React, { useEffect, useState } from "react"
import { SAL_PROPS } from "../../constants"

export const DesignedFor = ({ className }) => {
  const [items, setItems] = useState([...ITEMS])
  const [width, setWidth] = useState(0)
  const mobile = width <= 767

  useEffect(() => {
    const interval = setInterval(() => {
      setItems(items => [...items.slice(1), items[0]])
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    setWidth(window.innerWidth)
    const listener = window.addEventListener("resize", () =>
      setWidth(window.innerWidth)
    )
    return () => window.removeEventListener("resize", listener)
  }, [])

  return (
    <div
      id="designed-for"
      {...SAL_PROPS}
      className={`flex flex-col md:flex-row text-center ${className}`}
    >
      <div
        className={`heading-wrap flex md:flex-1 md:justify-center items-center text-right mr-7`}
      >
        <h2>Designed for</h2>
      </div>

      <div className={`relative text-left flex-1 justify-center items-center`}>
        {items.map((item, i) => {
          const relativePos = i - (ITEMS.length - 4)
          let y = relativePos * -85
          let opacity = 1 - Math.abs(relativePos) / 3
          if (mobile) {
            y = (i - (ITEMS.length - 7)) * -65
            opacity = i === items.length - 1 ? 0 : i * 0.17
          }

          return (
            <h2
              key={item}
              className="gradient2 absolute whitespace-nowrap"
              style={{
                opacity,
                top: mobile ? "75%" : "42%",
                transition: "opacity 300ms, transform 300ms",
                transform: `translate(0, ${y}px)`,
              }}
            >
              {item}
            </h2>
          )
        })}
      </div>
    </div>
  )
}

const ITEMS = [
  "Payments",
  "Exchanges",
  "Marketplaces",
  "Social",
  "Gaming",
  "DeFi",
  "NFTs + Collectibles",
  "The Metaverse",
]
