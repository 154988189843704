import React, { useEffect } from "react"
import sal from "sal.js"
import { Head } from "../components/sections/Head"
import { Header } from "../components/sections/Header"
import { SkyweaverCallout } from "../components/sections/SkyweaverCallout"
import { ContactForm } from "../components/sections/ContactForm"
import { Footer } from "../components/sections/Footer"
import { SupportedNetworks } from "../components/sections/SupportedNetworks"
import { DesignedFor } from "../components/sections/DesignedFor"
import { FeatureCarousel } from "../components/sections/FeatureCarousel"
import { IntegrationLayers } from "../components/sections/IntegrationLayers"
import { Blockchain } from "../components/sections/Blockchain"
import { Hero } from "../components/sections/Hero"
import { Features } from "../components/sections/Features"
import { Shimmer } from "../components/Shimmer"
import { MailingListSection } from "../components/MailingList"
import { CallToAction } from "../components/CallToAction"
// import { Testimonials } from "../components/sections/Testimonials"
import { init } from "../components/Init"

import "pure-react-carousel/dist/react-carousel.es.css"
import "sal.js/dist/sal.css"
import "../styles/index.js"

init()

export default function Home({ location }) {
  useEffect(() => {
    sal({ threshold: 0.1 })
  }, [])

  return (
    <div className="overflow-x-hidden">
      <Head />

      <Shimmer offset="0%" />

      <Header />

      <Hero className="max-w-4xl px-5 pt-7 pb-5 mb-10 sm:pb-8 sm:mt-9 sm:pt-10 md:mx-auto" />

      <Blockchain className="max-w-8xl px-5 pb-7 md:mx-auto" />

      <IntegrationLayers className="max-w-6xl pt-8 pb-10 mx-5 mt-7 mb-10 sm:pb-5 lg:px-5 md:mx-auto" />

      <MailingListSection className="max-w-7xl mt-5 pt-9 pb-9 mb-10 mx-5 md:mx-6 md:mt-0 xl:mx-auto" />

      <FeatureCarousel className="max-w-6xl px-5 pt-9 mb-10 md:mx-auto" />

      <Features className="max-w-8xl mb-5 mx-5 md:mb-10 md:pt-10 md:pb-10 md:px-2 md:mx-auto" />

      <DesignedFor className="max-w-4xl mt-10 mb-10 px-5 md:mx-auto" />

      <SupportedNetworks className="my-10 px-5 pt-10 mb-10 md:mx-auto" />

      <CallToAction className="max-w-2xl my-10 pt-10 pb-10 mb-10 md:mx-auto" />

      <Shimmer offset="-60%" />

      <SkyweaverCallout className="max-w-6xl mx-5 md:mx-auto px-5 py-10 mx-2 mb-10" />

      {/* <Testimonials className="max-w-6xl mx-5 md:mx-auto px-5 py-10 mx-2 mb-10" /> */}

      <ContactForm className="pt-8 pb-10 mb-10 px-5 md:mx-auto" />

      <Shimmer height="60vw" offset="-75%" />

      <Footer className="lg:px-8" />
    </div>
  )
}
