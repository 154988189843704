import React from "react"
import { useState } from "react"
import { FEATURES, SAL_PROPS } from "../../constants"

export const Features = ({ className }) => (
  <div className={`flex flex-col items-center lg:flex-row ${className}`}>
    <div className="flex-1 flex flex-col items-center justify-center text-center">
      <div {...SAL_PROPS} className="max-w-3xl">
        <h2 className="px-7">
          Our SDK + APIs make blockchain simple and user friendly
        </h2>

        <div className="my-3" />

        <p>
          And they give you everything you’ll need to run your application on
          the blockchain.
        </p>
      </div>

      <div className="mt-10 mx-auto w-full" style={{ maxWidth: 1200 }}>
        <div className="grid-container" {...SAL_PROPS}>
          {FEATURES.map(({ label, description, uri }, i) => (
            <FeatureBlock
              index={i + 1}
              key={label}
              uri={uri}
              description={description}
            >
              {label.replace("+", "<br/>+<br/>")}
            </FeatureBlock>
          ))}
        </div>
      </div>
    </div>
  </div>
)

const FeatureBlock = ({ children, index, description, uri }) => {
  const [active, setActive] = useState(false)
  return (
    <div
      role="button"
      tabIndex={index}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={() => uri && window.open(uri)}
      onKeyDown={e => e.key === "Enter" && uri && window.open(uri)}
      className={`feature-block item-${index} ${active ? "active" : ""}`}
    >
      <h4 className="gradient" dangerouslySetInnerHTML={{ __html: children }} />
      <div className="description">
        <p>{description}</p>
      </div>
    </div>
  )
}
