import React, { useEffect } from "react"
import { SAL_PROPS, VIDEO_URL } from "../../constants"

export const IntegrationLayers = ({ className }) => {
  useEffect(() => {
    const video = document.getElementById("video")

    video.addEventListener("suspend", () => {
      video.play()
    })
  }, [])

  return (
    <div
      {...SAL_PROPS}
      className={`flex flex-col-reverse items-center lg:flex-row ${className}`}
    >
      <div
        className="flex-1 w-full flex flex-col justify-center md:pr-7 lg:mr-3"
        style={{ maxWidth: 550 }}
      >
        <h2 style={{ maxWidth: 400 }}>
          A seamless user and developer experience for Ethereum
        </h2>

        <div className="my-4" />

        <p style={{ maxWidth: 500 }}>
          Sequence gives you a friendly smart wallet for your users, an
          end-to-end SDK to integrate Ethereum with your application, powerful
          blockchain APIs, and multi-chain support from the ground up to help
          you scale.
        </p>
      </div>

      <div className="flex-1">
        <video
          id="video"
          autoPlay
          muted
          loop
          playsInline
          controls={false}
          className="w-full"
          style={{ maxWidth: 550 }}
        >
          <source src={`${VIDEO_URL}`} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}
