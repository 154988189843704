import React from "react"
import { Carousel } from "../Carousel"
import { SAL_PROPS, URIS } from "../../constants"
import { Button } from "../Button"

export const FeatureCarousel = ({ className }) => (
  <div
    className={`flex flex-col items-center justify-between lg:flex-row ${className}`}
  >
    <div {...SAL_PROPS} className="w-full carousel-wrap lg:max-w-md">
      <Carousel />
    </div>

    <div
      {...SAL_PROPS}
      className="flex flex-col justify-center pt-7 w-full"
      style={{ maxWidth: 440 }}
    >
      <h2>An Ethereum wallet anyone can use</h2>

      <div className="my-3" />

      <p>
        Sequence is the multi-chain smart contract wallet that makes Web3 easy
        for everyone.
      </p>

      <div className="my-2" />

      <div className="flex justify-between">
        <ul className="features">
          <li>Social/email login</li>
          <li>Account recovery</li>
          <li>No seed phrases</li>
          <li>Web3 compatible</li>
          <li>Browser + app + extension</li>
        </ul>
        <ul className="features">
          <li>Multi-chain</li>
          <li>Smart contract</li>
          <li>Non-custodial</li>
          <li>No install necessary</li>
        </ul>
      </div>

      <div className="mt-5 flex">
        <Button href={URIS.learn}>Learn More</Button>
      </div>
    </div>
  </div>
)
