import React, { useState, useEffect } from "react"
import { useCallback, useRef } from "react"
import { HERO_IMAGES, HERO_IMAGE_SWAP_DURATION } from "../constants"
import linkImg from "../assets/link.png"

export const HeroLogo = ({ images, index, size }) => {
  const timeoutRef = useRef()
  const [frameIndex, setFrameIndex] = useState(0)

  const nextFrame = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setFrameIndex(i => (i >= images.length - 1 ? 0 : i + 1))
      timeoutRef.current = setTimeout(
        nextFrame,
        HERO_IMAGE_SWAP_DURATION * HERO_IMAGES.length
      )
    }, HERO_IMAGE_SWAP_DURATION * (index + 1) + Math.floor(Math.random() * 4) * 500)
  }, [images.length, index])

  useEffect(() => {
    nextFrame()
    return () => clearTimeout(timeoutRef.current)
  }, [nextFrame])

  return (
    <div className={`hero-logo hero-logo-${index}`}>
      <a
        className="flex justify-center items-center absolute h-full w-full"
        target="_blank"
        rel="noreferrer"
        href={images[frameIndex].href}
      >
        <CrossfadeImage
          alt="logo"
          delay={0}
          className="object-fit-contain absolute w-full h-full"
          src={images[frameIndex].src}
        />
        <div className="link flex items-center text-center color-white">
          {images[frameIndex].label}
          <img alt="link" className="ml-2" src={linkImg} />
        </div>
      </a>
    </div>
  )
}

const CrossfadeImage = ({
  src,
  style,
  alt,
  duration = 2000,
  delay = 0,
  className = "CrossfadeImage",
}) => {
  const [topSrc, setTop] = useState(src)
  const [bottomSrc, setBottom] = useState(src)
  const [bottomOpacity, setBottomOpacity] = useState(0)
  const [topOpacity, setTopOpacity] = useState(1)

  useEffect(() => {
    if (
      (src === topSrc && topOpacity === 1) ||
      (src === bottomSrc && bottomOpacity === 1)
    )
      return

    if (topOpacity === 1) {
      setBottom(src)
      setTimeout(() => setTop(bottomSrc), duration)
      setTopOpacity(0)
      setBottomOpacity(1)
    } else {
      setTimeout(() => setBottom(topSrc), duration)
      setTop(src)
      setTopOpacity(1)
      setBottomOpacity(0)
    }
  }, [src, bottomOpacity, duration, topOpacity, topSrc, bottomSrc])

  return (
    <div className={className}>
      {topSrc && (
        <img
          className="absolute"
          src={topSrc}
          alt={alt}
          style={{
            ...style,
            opacity: topOpacity,
            transition: `opacity ${duration}ms ease ${delay}ms`,
          }}
        />
      )}
      {bottomSrc && (
        <img
          className="absolute"
          src={bottomSrc}
          alt={alt}
          style={{
            ...style,
            opacity: bottomOpacity,
            transition: `opacity ${duration}ms ${delay}ms`,
          }}
        />
      )}
    </div>
  )
}
