import React, { useState } from "react"
import { Button } from "../Button"
import { utm_campaign, utm_content, utm_medium, utm_source, utm_term, ip, ip_as_num } from "../Init"
import { RECAPTCHA_SITE_KEY, ENV, LEAD_GEN_FORM_URL } from "../../constants"

export const ContactForm = ({ className }) => {
  const [success, setSuccess] = useState()
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [website, setWebsite] = useState("")
  const [about, setAbout] = useState("")
  const [aboutError, setAboutError] = useState(false)
  const [emailLock, setEmailLock] = useState(false)
  const emailValid = /^.+@.+\..+$/.test(email)
  const aboutIsEmpty = (about === '')

  const onSubmit = e => {
    e.preventDefault()
    if (emailLock) {
      return
    }
    if (e && e.target && emailValid && !aboutIsEmpty && !emailLock) {
      setEmailLock(true)
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute(RECAPTCHA_SITE_KEY[ENV], {action: 'submit'}).then(function(token) {
          submitForm(
            email, firstname, lastname, website, about, token,
            () => {
              setSuccess(true)
              setAboutError(false)
              setEmailError(false)
              setEmailLock(false)
            },
            () =>  {
              setSuccess(true)
              if (emailValid) {
                setEmailError(false)
              } else {
                setEmailError(true)
              }
              if (aboutIsEmpty) {
                setAboutError(true)
              } else {
                setAboutError(false)
              }
              setEmailLock(false)
            }
          )
        });
      });
    } else {
      setEmailLock(false)
      if (emailValid) {
        setEmailError(false)
      } else {
        setEmailError(true)
      }
      if (aboutIsEmpty) {
        setAboutError(true)
      } else {
        setAboutError(false)
      }
    }
  }

  const onChange = (f,ff = () => {}) => function (e) {
    f(e.target.value)
    ff()
  }

  return (
    <div id="contact" className={`relative container text-center ${className}`}>
      {!!success && <SuccessState />}

      <div
        className={`opacity-${success ? "0" : "1000"} pointer-events-${
          success ? "none" : "auto"
        }`}
        style={{ transition: "opacity 200ms" }}
      >
        <div className="max-w-xs sm:max-w-2xl mx-auto mb-8">
          <h2 className="mb-4">We're here to help</h2>
          <p>
            Sequence helps you build the next generation of Internet economies and experiences. <a rel="noreferrer" href="https://chat.sequence.build" target="_blank">Join our Discord</a> to chat with our team.<br/>For additional support, reach out below.
          </p>
        </div>

        <form
          className="flex justify-center items-center flex-col"
          onSubmit={onSubmit}
        >
          <div className="flex flex-col max-w-4xl mx-auto mb-4 w-full">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                position: "relative",
                top: success ? 0 : 20,
                width: "100%",
              }}
            >
              <Input name="email" label="Your Email" value={email}
                onChange={onChange(setEmail, () => {setEmailError(false)})}
                style={{
                  border: emailError ? "1px solid #7E4040" : "",
                }}
                error={emailError}
                errorMsg={"Oops! Please try another email"}
              />
            </div>
            <div className="flex-1 flex flex-col md:flex-row">
              <Input name="firstname" label="First name" value={firstname} onChange={onChange(setFirstname)} />
              <Input name="lastname" label="Last name" value={lastname} onChange={onChange(setLastname)} />
            </div>
            <Input name="website" label="Project Website" value={website} onChange={onChange(setWebsite)} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                position: "relative",
                top: success ? 0 : 20,
                width: "100%",
              }}
            >
            <TextArea name="about" label="Tell us about your project" value={about} onChange={onChange(setAbout, () => {setAboutError(false)})}
              style={{
                border: aboutError ? "1px solid #7E4040" : "",
              }}
              error={aboutError}
              errorMsg={"Please tell us about your project"}
             />
            </div>
          </div>

          <Button Component="button" type="submit">
            Submit
          </Button>
        </form>

        <p className="footnote  mx-auto mt-7">
          We typically respond to inquiries within 48 hours
          <br />
          You can also email us directly at{" "}
          <a href="mailto:hello@sequence.build">hello@sequence.build</a>
        </p>
      </div>
    </div>
  )
}

const Input = ({ name, label, value, onChange, style, error, errorMsg }) => (
  <InputBase name={name} label={label}>
    <input style={style} className="input" type="text" id={name} name={name} value={value} onChange={onChange} />
    <span style={{ opacity: error ? 0.6 : 0 }} className="error">
      {errorMsg}
    </span>
  </InputBase>
)

const TextArea = ({ name, label, value, onChange, style, error, errorMsg }) => (
  <InputBase name={name} label={label}>
    <textarea style={style} className="textarea" type="text" id={name} name={name} value={value} onChange={onChange} />
    <span style={{ opacity: error ? 0.6 : 0 }} className="error">
      {errorMsg}
    </span>
  </InputBase>
)

const InputBase = ({ name, label, children }) => (
  <div className="flex flex-1 flex-col text-left mx-2 mb-6">
    <label className="label mb-2" htmlFor={name}>
      {label}
    </label>
    {children}
  </div>
)

const SuccessState = () => (
  <div
    className="absolute inset-0 mx-auto flex flex-col align-center justify-center"
    style={{
      maxWidth: 610,
      transition: "opacity 200ms",
    }}
  >
    <h2 className="mb-4">Thank you</h2>
    <p>
      Your request has been submitted. We typically respond to inquiries within
      48 hours. You can also email us directly at hello@sequence.build
    </p>
  </div>
)

const submitForm = (email, firstname, lastname, website, about, token, onSuccess, onError) => {
  fetch(`${LEAD_GEN_FORM_URL[ENV]}`, {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      firstname,
      lastname,
      website,
      about,
      token,
      utm_content,
      utm_campaign,
      utm_source,
      utm_medium,
      utm_term,
      referrer: document.referrer,
      ip,
      ip_as_num,
    }),
  })
  .then(onSuccess)
  .catch(onError)
}
