import React from "react"
import { Button } from "../../components/Button"
import { SAL_PROPS, URIS } from "../../constants"
import logoPng from "../../assets/sequence-logo.png"

export const Hero = ({ className }) => (
  <div className={`hero container ${className}`}>
    <div
      {...SAL_PROPS}
      className="mx-auto flex flex-col justify-center text-center items-center"
    >
      <div className="sm:hidden mb-6">
        <img alt="logo" src={logoPng} />
      </div>

      <h1 className="mt-2 mb-5 px-6">
        Build user-friendly Web3 applications with ease
      </h1>

      <p className="large mx-auto text-light-gray mb-6">
        Sequence is the developer platform + smart wallet for dapps on Ethereum
        and other EVM networks
      </p>

      <div className="hidden sm:block mt-2">
        <Button href={URIS.docs}>Start Building</Button>
      </div>

      <div className="sm:hidden">
        <Button href={URIS.docs}>Start Building</Button>
        <div className="my-4" />
        <Button variant="outlined" href={URIS.wallet} target="_blank">
          Launch Wallet
        </Button>
      </div>
    </div>
  </div>
)
