import React from "react"
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel"
import slide1 from "../assets/carousel/1.png"
import slide2 from "../assets/carousel/2.png"
import slide3 from "../assets/carousel/3.png"
import slide4 from "../assets/carousel/4.png"
import slide5 from "../assets/carousel/5.png"

const SLIDES = [slide2, slide1, slide3, slide4, slide5]

export const Carousel = () => (
  <CarouselProvider
    isPlaying
    naturalSlideWidth={100}
    naturalSlideHeight={125}
    totalSlides={5}
  >
    <Slider>
      {SLIDES.map((slide, i) => (
        <Slide key={i} index={i}>
          <img
            alt="features"
            className="rounded w-full h-full object-fit-contain"
            src={slide}
          />
        </Slide>
      ))}
    </Slider>

    <div className="mt-6">
      <DotGroup />
    </div>
  </CarouselProvider>
)
