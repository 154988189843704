import React from "react"
import skyweaverPng from "../../assets/skyweaver-logo.png"
import skyweaverBGPng from "../../assets/skyweaverbg.png"
import { SAL_PROPS } from "../../constants"
import { Button } from "../Button"

export const SkyweaverCallout = ({ className }) => (
  <div {...SAL_PROPS} className={`relative text-center z-30 ${className}`}>
    <SkyweaverBackground />
    <div className="mx-auto max-w-xl flex flex-col items-center px-2 py-3 text-white">
      <img alt="skyweaver" src={skyweaverPng} width={300} />
      <h3 className="text-white mt-5 mb-7">Powered by Sequence</h3>

      <p className="text-white">
        The digital trading card game where players actually own, trade and sell
        their NFT cards. By ranking highly on the competitive leaderboard,
        players win tradable items. With the seamless Sequence Wallet and
        marketplace, players can easily buy, store, sell and trade items with
        one another.
        <br />
        <br />
        Skyweaver is pioneering community-owned economies in gaming, and it's
        powered by Sequence.
      </p>

      <Button
        className="mt-7"
        component="a"
        href="https://skyweaver.net/"
        target="_blank"
      >
        Visit Skyweaver
      </Button>
    </div>
  </div>
)

const SkyweaverBackground = () => (
  <div className="absolute inset-0" style={{ zIndex: -1 }}>
    <img
      className="object-cover object-center sm:object-left md:object-center w-full h-full relative rounded z-10"
      alt="skyweaverbg"
      src={skyweaverBGPng}
    />
  </div>
)
